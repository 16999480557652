import { defineStore } from 'pinia';

export const useCommonStore = defineStore('common', {

    state: () => ({
        showSidebar: false,
        catalog: null as any
    }),
    getters: {

    },

    actions: {
        async fetchMenu() {
            try {
                const { data } = await useApiFetch('menu', {
                    tempGuest: true,
                    headers: {
                        'app-version': useRuntimeConfig().public.appVersion,
                    },
                });
                this.catalog = data.value;
                return this.catalog;
            } catch (error) {
                this.catalog = null;
            }
        }
    },
});